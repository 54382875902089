
import { IonCard, IonCardContent } from "@ionic/vue";
import { getDateTime,getDate } from "@/utils/moment";

export default {
  name: "recruitArticle",
  components: { IonCard, IonCardContent },
  props: {
    id: {
      type: [Number, String],
      default: 0,
    },
    title: {
      type: String,
      default: "",
    },
    categroys: {
      type: Array,
      default: () => [],
    },
    dateTime: {
      type: String,
      default: "",
    },
    address: {
      type: String,
      default: "",
    },
  },
  emits: {
    skip: null,
  },
  setup(props: any, { emit }: any) {
    const handle = () => {
      emit("skip", props.id);
    };

    return { handle, getDateTime,getDate };
  },
};


import {
  IonPage,
  IonHeader,
  IonContent,
  IonSelect,
  IonSelectOption,
  onIonViewDidEnter,
  onIonViewWillEnter,
} from "@ionic/vue";
import OptionMenu from "@/components/optionMenu.vue";
import recruitArticle from "@/components/recruitArticle.vue";
import { skipRouter } from "@/router";

import { onMounted, watch, ref } from "vue";
import { getRecruitList, getTenderList } from "@/service/discover";

export default {
  name: "DiscoverList",
  components: {
    OptionMenu,
    recruitArticle,
    IonHeader,
    IonContent,
    IonPage,
    IonSelect,
    IonSelectOption,
  },
  setup() {
    const data = {
      menus: [
        {
          label: "招聘信息",
          value: "2",
          getData: getRecruitList,
          detailPath: "recruitDetail",
        },
        {
          label: "企业招投标",
          value: "1",
          getData: getTenderList,
          detailPath: "tenderDetail",
        },
      ],
      checkedMenus: ref("2"),
      articles: ref([]) as any,
      loadStatus: ref(false),
      currentPage: 0,
      pageSize: 10,
    };

    const methods = {
      async getList() {
        const { getData } = data.menus.find(
          (menu) => menu.value === data.checkedMenus.value
        ) as any;
        const { rows, page, total }: any = await getData({
          proid: "",
          page: data.currentPage + 1,
          rows: data.pageSize,
        });
        data.articles.value = [...data.articles.value, ...rows];
        data.currentPage = page;
        data.loadStatus.value = page >= total;
      },
      refresh(event: any) {
        methods.defaultData();
        setTimeout(async () => {
          await methods.getList();
          event.target.complete();
        }, 200);
      },
      loadMore(event: any) {
        setTimeout(async () => {
          await methods.getList();
          event.target.complete();
        }, 200);
      },
      defaultData() {
        data.articles.value = [];
        data.currentPage = 0;
        data.loadStatus.value = false;
      },
      skip(id: any) {
        const { detailPath } = data.menus.find(
          (menu) => menu.value === data.checkedMenus.value
        ) as any;
        skipRouter(`/tabs/discover/${detailPath}`, {
          id,
        });
      },
    };

    //进入页面并且渲染完页面后触发，每次进入页面都触发
    onIonViewDidEnter(() => {
      methods.getList();
    });
    //进入页面后触发，每次进入页面都触发
    onIonViewWillEnter(() => {
      console.log("onIonViewWillEnter!");
    });

    watch(data.checkedMenus, () => {
      methods.defaultData();
      methods.getList();
    });
    return { ...data, ...methods };
  },
};
